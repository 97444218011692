/** @format */

import {
  faBellRing,
  faCircleCheck,
  faPaperPlane,
  faTriangleExclamation,
  faCircleExclamation,
  faXmark,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@mui/material';
import { closeSnackbar, SnackbarContent } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { getApiUrl } from '../../api.ts';
import { ThemeProvider } from '../../theme';
import palette from '../../theme/palette';

export const webSocketNotificationIcons = {
  email: faPaperPlane,
  warning: faTriangleExclamation,
  error: faXmark,
  default: faBellRing,
};

const getIcon = (iconName) => {
  if (iconName && webSocketNotificationIcons[iconName]) {
    return webSocketNotificationIcons[iconName];
  }
  return webSocketNotificationIcons.default;
};

export const getColor = (colorName) => {
  if (
    palette[colorName] &&
    palette[colorName].main &&
    palette[colorName].contrastText
  ) {
    return {
      color: palette[colorName].contrastText,
      bgcolor: palette[colorName].main,
    };
  }
  return {
    color: 'error.constrastText',
    bgcolor: 'error.main',
  };
};

const WebSocketNotification = forwardRef((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    // as well as your own custom props 👇🏼
    title,
    customColor,
    customIcon,
    customUserAvatarId,
    customIsImportant,
    ...other
  } = props;

  const isRemoteIcon = useMemo(
    () => customIcon && customIcon.startsWith('http'),
    [customIcon],
  );

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <ThemeProvider>
        <Paper
          sx={{
            width: '100%',
            ...(customIsImportant && {
              bgcolor: 'error.main',
              color: 'white',
              fontWeight: 'bold',
            }),
          }}
          elevation={24}
        >
          <List>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => closeSnackbar(id)}
                  sx={{ width: 'auto', color: 'inherit' }}
                >
                  <FontAwesomeIcon icon={faCircleCheck} />
                </IconButton>
              }
              sx={{
                paddingRight: '72px',
                '& .MuiListItemSecondaryAction-root': {
                  width: 40,
                },
              }}
            >
              <ListItemAvatar>
                {customUserAvatarId ? (
                  <Avatar src={`${getApiUrl()}/avatar/${customUserAvatarId}`} />
                ) : (
                  <Avatar
                    src={isRemoteIcon ? customIcon : undefined}
                    sx={!isRemoteIcon && getColor(customColor)}
                  >
                    {!isRemoteIcon && (
                      <FontAwesomeIcon icon={getIcon(customIcon)} />
                    )}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={title || message}
                secondary={title ? message : ''}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: 'inherit',
                    fontWeight: 'inherit',
                  },
                  '& .MuiListItemText-secondary': {
                    color: customIsImportant ? 'inherit' : undefined,
                    fontWeight: 'inherit',
                  },
                }}
              />
            </ListItem>
          </List>
        </Paper>
      </ThemeProvider>
    </SnackbarContent>
  );
});

WebSocketNotification.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  customColor: PropTypes.string,
  customIcon: PropTypes.string,
  customUserAvatarId: PropTypes.string,
  customIsImportant: PropTypes.bool,
};

WebSocketNotification.defaultProps = {
  title: null,
  customColor: null,
  customIcon: null,
  customUserAvatarId: null,
  customIsImportant: false,
};

export default WebSocketNotification;
